/* eslint-disable */
class Header {
	constructor() {
		this.mobileMenuIconClose = document.getElementById(
			"mobile-menu-icon-close"
		);
		this.mobileMenuIconOpen = document.getElementById("mobile-menu-icon-open");
		this.headerContentDecoration = document.querySelectorAll(
			".header__content--decoration "
		)[0];
		this.header = document.querySelectorAll(".js-header")[0];
		this.modifyFontTextShort = document.querySelectorAll(
			".header__modify-font-size--text-short"
		)[0];
		this.modifyFontTextLarge = document.querySelectorAll(
			".header__modify-font-size--text-large"
		)[0];
		this.body = document.getElementsByTagName("body")[0];
		this.initHeader();
	}

	initHeader() {
		window.onscroll = function () {
			scrollFunction();
		};

		this.modifyFontTextLarge.addEventListener(
			"click",
			function (event) {
				event.preventDefault();
				document.getElementsByTagName("body")[0].classList.toggle("fs");
				largeFontSize = !largeFontSize;
			},
			false
		);

		this.modifyFontTextShort.addEventListener(
			"click",
			function (event) {
				event.preventDefault();
				document.getElementsByTagName("body")[0].classList.toggle("fs");
				largeFontSize = !largeFontSize;
			},
			false
		);

		function scrollFunction() {
			const h = document.querySelectorAll(".js-header")[0];
			if (
				document.body.scrollTop > 50 ||
				document.documentElement.scrollTop > 50
			) {
				h.classList.add("header--shrink");
			} else {
				h.classList.remove("header--shrink");
			}
		}

		// Set a CSS variable to fix Webkit-based mobile phones handling of vh units.
		// You can use it with the vh() SASS function.
		function setCSSvh() {
			document.documentElement.style.setProperty(
				"--vh",
				`${window.innerHeight / 100}px`
			);
		}

		window.addEventListener("resize", setCSSvh);
		window.addEventListener("orientationchange", setCSSvh);
		setCSSvh();
	}

	toggleMenu() {
		this.mobileMenuIconClose.classList.toggle("hidden");
		this.mobileMenuIconOpen.classList.toggle("hidden");
		this.headerContentDecoration.classList.toggle("hidden");
		this.header.classList.toggle("header--open");
		document.querySelectorAll("body")[0].classList.toggle("u-block-menu"); // Force not scroll when open menu
		/* TODO: Save position and scroll to previous position */
	}
}

export default Header;
