/* eslint-disable */
class Slider {
	constructor() {
		this.init();
	}

	init() {
		Array.from(document.querySelectorAll(".js-swiper-slider")).forEach((el) =>
			this.initSlider(el, "auto")
		);
	}

	initSlider(el, spv) {
		/* eslint-disable no-unused-vars, no-undef */
		const swiper = new Swiper(el, {
			loop: true,
			simulateTouch: true,
			freeMode: true,
			slidesPerView: 1,
			spaceBetween: 16,
			navigation: {
				nextEl: el.querySelector(".swiper-button-next"),
				prevEl: el.querySelector(".swiper-button-prev"),
			},
			breakpoints: {
				992: {
					slidesPerView: 4,
					spaceBetween: 34,
				},
			},
			touchEnd: () => {
				setTimeout(() => {
					window.isSwipingSlider = false;
				}, 100);
			},
		});
		/* eslint-enable no-unused-vars, no-undef */
	}
}

export default Slider;
