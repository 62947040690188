/*
Services. Check https://github.com/AmauriC/tarteaucitron.js/blob/master/tarteaucitron.services.js if you need to add more services.
Then adapt the service taking https://github.com/zemax/tartemeringuee/tree/master/services as an example.
*/
import gtm from "tartemeringuee/services/gtm";
import consentUI from "../../lib/cookies/consent-ui";
import consentManager from "../../lib/cookies/consent-manager";

const Cookies = () => {
	if (typeof cookiesPolicyLink !== "undefined") {
		/* eslint-disable */
		const consent = consentManager();
		if (
			typeof googleTagManagerId !== "undefined" &&
			googleTagManagerId !== ""
		) {
			consent
				.register(gtm(googleTagManagerId))
				.setUI(
					consentUI({
						getText: getText({ privacyURL: cookiesPolicyLink }),
					})
				)
				.launch();
		}
	}
};

export default Cookies;
