/* eslint-disable */
import LazyLoading from "./lib/lazy-loading.js";
import Grid from "./template-parts/sections/grid.js";
import Hero from "./template-parts/sections/hero.js";
import Locations from "./template-parts/sections/locations.js";
import ContactForm from "./template-parts/sections/contact-form.js";
import Slider from "./template-parts/sections/slider.js";
import Map from "./template-parts/sections/map.js";
export default class ModulesLoader {
	constructor(el = document) {
		this.el = el;
	}

	loadModules() {
		window.lazyLoading = new LazyLoading();
		window.grid = new Grid();
		window.hero = new Hero();
		window.locations = new Locations();
		window.contactForm = new ContactForm();
		window.map = new Map();
		window.slider = new Slider();
	}
}
