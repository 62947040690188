/* eslint-disable */
class Map {
	constructor() {
		this.mapContainer = document.getElementsByClassName("map")[0];
		if (this.mapContainer !== undefined) {
			this.latitude = document.getElementById("map").dataset.latitude;
			this.longitude = document.getElementById("map").dataset.longitude;
			if (window.googleapi === false) {
				if (this.latitude !== undefined && this.longitude !== undefined) {
					window.loadJS(
						"https://maps.googleapis.com/maps/api/js?key=AIzaSyBoKyO_xmBX7u9qJTsxV4QJvxFekeH2gjQ&callback=window.map.init"
					);
					window.googleapi = true;
				}
			} else {
				this.init();
			}
		}
	}
	init() {
		let directionsDisplay =
			new google.maps.DirectionsRenderer(); /* eslint-disable-line */
		let height = new google.maps.LatLng(
			this.latitude,
			this.longitude
		); /* eslint-disable-line */
		const mapOptions = {
			zoom: 17,
			zoomControl: false,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: false,
			center: height,
		};
		let map = new google.maps.Map(
			document.getElementById("map"),
			mapOptions
		); /* eslint-disable-line */
		const marker = new google.maps.Marker({
			/* eslint-disable-line */ position: height,
			map: map,
			title: "Món Sant Benet",
		});

		directionsDisplay.setMap(map);

		// please note,
		// that IE11 now returns undefined again for window.chrome
		// and new Opera 30 outputs true for window.chrome
		// and new IE Edge outputs to true now for window.chrome
		// and if not iOS Chrome check
		// so use the below updated condition
		const isChromium = window.chrome;
		const winNav = window.navigator;
		const vendorName = winNav.vendor;
		const isOpera = winNav.userAgent.indexOf("OPR") > -1;
		const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
		const isIOSChrome = winNav.userAgent.match("CriOS");

		if (isIOSChrome) {
			// is Google Chrome on IOS
		} else if (
			(isChromium !== null &&
				isChromium !== undefined &&
				vendorName === "Google Inc." &&
				isOpera === false &&
				isIEedge === false) ||
			navigator.userAgent.indexOf("Safari") !== -1
		) {
			// is Google Chrome or Safari
			document.addEventListener("DOMContentLoaded", function (event) {
				document.getElementById("panel").style.display = "none";
			});
		} else {
			// not Google Chrome
		}
	}
}

export default Map;
