/* eslint-disable */
import LazyLoad from "vanilla-lazyload";

class LazyLoading {
	constructor() {
		this.replaceNoscript();
		this.loadImages();
	}

	replaceNoscript() {
		const noscript = document.body.querySelectorAll(".image-wrapper noscript");
		let img;
		let parent;

		for (let i = 0; i < noscript.length; i++) {
			img = document.importNode(
				new DOMParser().parseFromString(noscript[i].textContent, "text/html")
					.body.firstChild,
				true
			); /* eslint-disable-line */
			parent = noscript[i].parentElement;
			parent.replaceChild(img, noscript[i]);

			const sources = parent.getElementsByTagName("source");
			for (let j = 0; j < sources.length; j++) {
				const currentSrcset = sources[j].getAttribute("srcset");
				sources[j].removeAttribute("srcset"); // remove old srcet data
				sources[j].setAttribute("data-srcset", currentSrcset);
			}

			const imgs = parent.getElementsByTagName("img");

			for (let k = 0; k < imgs.length; k++) {
				const currentSrc = imgs[k].getAttribute("src");
				imgs[k].removeAttribute("src"); // remove old src data
				imgs[k].setAttribute("data-src", currentSrc);
			}
		}
	}

	loadImages() {
		const lazyLoad = new LazyLoad({ elements_selector: ".lazy" });
		window.lazyLoad = lazyLoad;
	}
}

export default LazyLoading;
