/* eslint-disable */
class Hero {
	constructor() {
		this.sliders = [];
		this.slidersNumber = 0;
		this.init();
	}

	init() {
		Array.from(document.querySelectorAll(".swiper-slider-extra")).forEach(
			(el) => this.initSlider(el, "auto")
		);
	}

	initSlider(el, spv) {
		/* eslint-disable no-unused-vars, no-undef */
		const swiper = new Swiper(el, {
			simulateTouch: true,
			freeMode: true,
			slidesPerView: 1,
			spaceBetween: 16,
			navigation: {
				nextEl: el.querySelector(".swiper-button-next"),
				prevEl: el.querySelector(".swiper-button-prev"),
			},
			pagination: {
				el: ".swiper-pagination",
				type: "fraction",
				formatFractionCurrent: function (number) {
					if (number <= 9) {
						return "0" + number;
					} else {
						return number;
					}
				},
				formatFractionTotal: function (number) {
					if (number <= 9) {
						return "0" + number;
					} else {
						return number;
					}
				},
			},
			breakpoints: {
				992: {
					slidesPerView: 1,
					spaceBetween: 0,
				},
			},
			on: {
				touchStart: () => {
					setTimeout(() => {
						window.isSwipingSlider = true;
					}, 100);
				},
				touchEnd: () => {
					setTimeout(() => {
						window.isSwipingSlider = false;
					}, 100);
				},
			},
		});

		/* eslint-enable no-unused-vars, no-undef */
	}
}

export default Hero;
